.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: rgb(41, 41, 41);
  overflow-x: hidden;
}

.push-enter {
  transform: translateX(100vw);
}

.push-enter-active {
  transform: translateX(0);
  transition: transform 0.3s;
}

.push-exit {
  transform: translateX(0);
}

.push-exit-active {
  transform: translateX(100vw);
  transition: transform 0.3s;
}

.gentle-push-enter {
  transform: translateX(88px);
}

.gentle-push-enter-active {
  transform: translateX(0);
  transition: transform 0.2s;
}

.gentle-push-exit {
  transform: translateX(10px);
}

.gentle-push-exit-active {
  transform: translateX(88px);
  transition: transform 0.2s;
}

.flip-enter {
  transform: rotateY(180deg);
  transition: transform 0.7s;

  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-enter-active {
  transform: rotateY(0deg);
  transition: transform 0.7s;

  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-exit {
  transform: rotateY(180deg);
  transition: transform 0.7s;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-exit-active {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-alt-enter {
  transform: rotateY(-270deg);
  transition: transform 0.7s;

  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-alt-enter-active {
  transform: rotateY(0deg);
  transition: transform 1s;

  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-alt-exit {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-alt-exit-active {
  transform: rotateY(-270deg);
  transition: transform 0.7s;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

